<template>
  <section class="g-cats" v-if="show">
    <category-item
        v-for="(category, index) in subcategoriesIntern"
        :category="category"
        :key="index"
    />
  </section>
</template>

<script>
import CategoryItem from "./CategoryItem";
import {mapGetters} from "vuex";

export default {
  name: "CategorySubcategories",
  components: {
    CategoryItem,
  },
  props: {
    subcategories: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory"
    }),
    show() {
      return this.subcategoriesIntern && this.subcategoriesIntern.length > 0
    },
    subCategoriesFromSelectedCategory() {
      return this.selectedCategory?.subCategory
    },
    subcategoriesIntern() {
      if (this.subcategories) return this.subcategories
      return this.subCategoriesFromSelectedCategory
    }
  },
}
</script>