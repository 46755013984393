<template>
  <div>
    <catalogue-header v-if="displayHeader" />

    <catalogue-searchbar v-if="displaySearchBar" />

    <div class="category-page">
      <div class="bb-container">
        <catalogue-breadcrumb class="category-page__breadcrumbs" />
        <div class="g-catalogo">
          <catalogue-categories-nav />

          <div v-if="selectedCategory" class="g-catalogo__content">
            <main>
              <catalogue-category-header :category="selectedCategory" />

              <category-subcategories />

              <h2 class="g-catalogue-footer__title">
                {{ selectedCategory.catalogueTitle }}
              </h2>

              <LazyHydrate when-visible>
                <catalogue-filters />
              </LazyHydrate>
              <LazyHydrate when-visible>
                <applied-filters />
              </LazyHydrate>
              <catalogue-products
                :parameters="parameters"
                ref="catalogueProducts"
                :route="currentRoute"
              />
            </main>

            <footer class="g-catalogue-footer">
              <LazyHydrate when-visible>
                <entity-collections :entity="selectedCategory"></entity-collections>
              </LazyHydrate>

              <LazyHydrate when-visible>
                <div
                  class="g-catalogue-footer__section"
                  v-if="selectedCategory.posts && selectedCategory.posts.length > 0"
                >
                  <h3 class="g-catalogue-footer__title">Artículos relacionados</h3>

                  <div class="g-catalogue-footer__content">
                    <div class="g-post-slider">
                      <related-posts
                        main-class="g-post-slider__items"
                        :posts="selectedCategory.posts"
                      ></related-posts>
                    </div>
                  </div>
                </div>
              </LazyHydrate>

              <LazyHydrate when-visible>
                <category-content :category="selectedCategory"></category-content>
              </LazyHydrate>
            </footer>

            <LazyHydrate when-visible>
              <CtaPregnant class="cta-pregnant--catalog" />
            </LazyHydrate>
          </div>
        </div>
      </div>
    </div>
    <LazyHydrate when-visible>
      <catalogue-porque />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <categories-directory></categories-directory>
    </LazyHydrate>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppliedFilters from "../../components/AppliedFilters";
import CanFilterProducts from "../mixins/CanFilterProducts";
import LazyHydrate from "vue-lazy-hydration";
import DisplayHeader from "../mixins/DisplayHeader";
import CatalogueHeader from "../catalogue/components/CatalogueHeader";
import DisplaySearchBar from "../mixins/DisplaySearchBar";
import CanGetProductsAtServerSide from "../mixins/CanGetProductsAtServerSide";
import CatalogueCategoriesNav from "../catalogue/components/CatalogueCategoriesNav";
import CatalogueCategoryHeader from "./components/CategoryHeader";
import CatalogueFilters from "../catalogue/components/CatalogueFilters";
import CategorySubcategories from "./components/CategorySubcategories";
import CatalogueProducts from "../catalogue/components/CatalogueProducts";
import CatalogueBreadcrumb from "../catalogue/components/CatalogueBreadcrumb";
import HasBreadcrumbListMetaInfo from "./mixins/HasBreadcrumbListMetaInfo";

export default {
  name: "CategoryPage",
  mixins: [
    CanFilterProducts,
    DisplayHeader,
    DisplaySearchBar,
    CanGetProductsAtServerSide,
    HasBreadcrumbListMetaInfo,
  ],
  metaInfo() {
    return {
      title: this.selectedCategory?.title + " | HelloBB",
      meta: [
        {
          name: "description",
          content: this.selectedCategory?.description?.replace(
            /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
            ""
          ),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.hellobb.net" + this.$route.path,
        },
      ],
      script: this.scriptBreadCrumbList(),
    };
  },
  components: {
    CatalogueProducts,
    CatalogueCategoryHeader,
    CatalogueCategoriesNav,
    CatalogueFilters,
    AppliedFilters,
    CategorySubcategories,
    CatalogueBreadcrumb,
    CatalogueSearchbar: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CatalogueSearchbar" */ "../../components/CatalogueSearchbar.vue"
      ),
    CataloguePorque: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CataloguePorque" */ "../catalogue/components/CataloguePorque"
      ),
    CatalogueHeader,
    CategoriesDirectory: () =>
      import(
        /* webpackChunkName: "CategoriesDirectory" */
        /* webpackPrefetch: true */ "../catalogue/components/CategoriesDirectory"
      ),

    // CatalogueHeader: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "CatalogueHeader" */  '../catalogue/components/CatalogueHeader'), // NO FUNCIONA, ni idea pq?

    CtaPregnant: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaPregnant" */ "../cta/CtaPregnant.vue"
      ),
    EntityCollections: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "EntityCollections" */ "../catalogue/components/EntityCollections"
      ),
    CategoryContent: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CategoryContent" */ "./components/CategoryContent"
      ),
    RelatedPosts: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "RelatedPosts" */ "../catalogue/components/RelatedPosts"
      ),

    LazyHydrate,
  },
  data() {
    return {
      orderBy: "relevance",
      currentRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      savedOrderBy: "orderBy",
      selectedCategory: "selectedCategory",
    }),
    categoryName() {
      return this.selectedCategory?.name;
    },
    title() {
      return this.$route.params.slug;
    },
    imageUrl() {
      return "'/" + this.title + ".jpeg'";
    },
    parameters() {
      return {
        slug: this.currentRoute?.params?.slug,
        page: 1,
        order: this.orderBy,
      };
    },
  },
  methods: {
    getProducts(server = false) {
      this.orderBy = this.savedOrderBy;
      let parameters = { ...this.parameters, ...{ server: server } };
      parameters = this.applyFilters(parameters, false);
      return this.$store.dispatch("getProducts", parameters);
    },
  },
  created() {
    this.currentRoute = this.$route;
  },
  async mounted() {
    console.log(this.selectedCategory)
    if (!this.productsPrefetched) {
      await this.getProducts();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentRoute = to;
    await this.getProducts(to);
    next();
  },
};
</script>
