<template>
  <div
    class="g-cats-item"
    @click="gotoCategory(category)"
  >
    <div class="g-cats-item__picture">
      
    </div>

    <div class="g-cats-item__content">
      <h2 class="g-cats-item__title">{{ category.name }}</h2>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GotoCategory from "../../catalogue/components/mixins/GotoCategory";

export default {
  name: 'CategoryItem',
  mixins: [ GotoCategory ],
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      categories: "categories",
    }),
  },
  methods: {
    categoryImageUrl(category) {
      return "/" + category.name + ".png";
    },
  }
}
</script>